/* eslint-disable indent */
import { useLocation, useNavigate, useSearchParams } from '@solidjs/router';
import { For, Match, Show, Switch, createEffect, createSignal, on, onCleanup, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Grid } from '../../grid-system/grid/grid';
import { Section } from '../../grid-system/section/section';
import {
    changeAreaOfCare,
    changeSelectedProductCategory,
    changeSelectedType,
    clearFilters,
    orderSorter,
    productsStore,
    setAvailableAreasOfCare,
    setIsMobile,
    setProductsStore,
} from '../../stores/products-store';
import theme from '../../style/theme';
import { ErrorCatcher } from '../../tools/error-catcher';
import { FilterList } from '../../ui-components/filter-list/filter-list';
import { Heading } from '../../ui-components/heading/heading';
import { Checkbox } from '../../ui-components/inputs/checkbox/checkbox';
import { Radio } from '../../ui-components/inputs/radio/radio';
import { StyledSidebar, StyledSidebarContainer, StyledSidebarContainerLine, StyledSidebarInner, gridSettings } from '../../ui-components/layouts/sidebar.style';
import { ProductCategory, ProductListCardData } from '../product/product-types';
import { fetchProductCategories, fetchProducts } from './fetch-products';
import { MobileProductsSidebar } from './mobile/mobile-sidebar';
import { ProductCard } from './product-card/product-card';
import { CategoryWithData, NestedCategory, ProductsProps } from './products-types';
import { StyledClearAllContainer, StyledFilterHeadingContainer, StyledHeadingWrapper, StyledProductsContainer, StyledProductsList } from './products.style';
import { LoadingPlaceHolder } from '../loading-place-holder/loading-place-holder';
import { Button } from '../../ui-components/button/button';

export const createGroupedProductCard = (product: ProductListCardData) => {
    return createProductCard(product, true);
};

const createProductCard = (product: ProductListCardData, inGrouping?: boolean) => {
    return <ProductCard data={product} inGrouping={inGrouping} />;
};

const extractCategoriesFromProducts = (products: ProductListCardData[]) => {
    if (!products) {
        return null;
    }

    const categoriesWithProducts: any[] = [];

    products.forEach((prod: ProductListCardData) => {
        prod.tags?.forEach((tag: string) => {
            if (!categoriesWithProducts.includes(tag)) {
                categoriesWithProducts.push(tag);
            }
        });
    });

    return categoriesWithProducts;
};

export const sortAlphabetically = (target: CategoryWithData) => {
    const sortedAreasOfCare: { [key: string]: any } = {};

    Object.keys(target)
        .filter((k) => k !== '_data')
        .sort((a, b) => a.localeCompare(b))
        .forEach((key) => {
            sortedAreasOfCare[key] = target[key];
        });        

    return sortedAreasOfCare;
};

export const sortByOrder = (nestedCategories: CategoryWithData) => {
    const sortedAreasOfCare: { [key: string]: any } = {};

    Object.keys(nestedCategories)
        .map((key) => nestedCategories[key])
        .sort(orderSorter)
        .forEach((category) => {
            if (category?._data?.slug) {
                sortedAreasOfCare[category._data.slug] = category;
            }
        });
    
    return sortedAreasOfCare;
};


export const Products = (props: ProductsProps) => {
    const { viewport, localize } = useContext(AppContext);
    const [categoriesAndChildren, setCategoriesAndChildren] = createSignal<{ [key: string]: string[]}>({});

    const location = useLocation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const productsData = fetchProducts({ tag: props?.tag });
    const loaded = () => productsData();

    const freshProductCategories = fetchProductCategories();

    createEffect(() => {
        const parsedNestedCategories = buildNestedCategories(freshProductCategories(), productsData());

        if (!parsedNestedCategories) {
            return;
        }        

        const alphabetizedAoc = sortAlphabetically(parsedNestedCategories as CategoryWithData);
        const orderedAoc = sortByOrder(alphabetizedAoc);

        setAvailableAreasOfCare(orderedAoc);
        setProductsStore({
            labels: {
                heading: localize('products', 'Products'),
                topCategoryHeading: localize('area-of-care', 'Area of care'),
                firstNestedCategoryHeading: localize('product-category', 'Product category'),
                secondNestedCategoryHeading: localize('type-of-product', 'Type of product'),
            }
        });
    });

    const buildNestedCategories = (categories: ProductCategory[], productsData: ProductListCardData[]) => {
        const categoriesWithProducts = extractCategoriesFromProducts(productsData) || [];

        const categoryMapById = new Map();
        const categoryParentsMapBySlug = new Map();
    
        // Populate maps - one for each category (by id), and one for each category's children (by slug)
        // This is to make it easier and efficient to build the nested structure and the flat list of categories and their children
        categories.forEach(category => {
            categoryMapById.set(category.categoryId, {
                '_data': {
                    name: category.name,
                    slug: category.slug,
                    parent: category.parent,
                    categoryOrderRanking: category.categoryOrderRanking,
                    categoryId: category.categoryId,
                }
            });

            categoryParentsMapBySlug.set(category.slug, []);
        });

        const nestedCategories: CategoryWithData = {};
        const categoryChildrenFlatList: { [key: string]: any[] } = {};
    
        // Build the nested category structure
        categories.forEach(category => {
            // Exclude categories that don't have any products
            if (!categoriesWithProducts?.includes(category.slug)) {
                return;
            }
    
            if (category.parent === 0) {
                nestedCategories[category.slug] = categoryMapById.get(category.categoryId);
            } else {
                const parentCategory = categoryMapById.get(category.parent);
                if (parentCategory) {
                    parentCategory[category.slug] = categoryMapById.get(category.categoryId);
                }
            }
        });

        // Create the initial flat list of children
        categoriesWithProducts.forEach((categorySlug) => {
            categoryChildrenFlatList[categorySlug] = [];
        });

        // Populate the flat list of children for each category (including children of children, leading to arrays of arrays)
        categories.forEach(category => {
            if (category.parent === 0) {
                return;
            }

            const parentCategory = categoryMapById.get(category.parent);

            if (!parentCategory) {
                return;
            }

            const parentSlug = parentCategory._data.slug;
            
            if (!parentSlug || !categoryChildrenFlatList[parentSlug]) {
                return;
            }

            categoryChildrenFlatList[parentSlug]?.push(category.slug, categoryChildrenFlatList[category.slug]);
        });

        // Flatten the arrays of arrays to get a single array of children for each category
        Object.keys(categoryChildrenFlatList).forEach((categorySlug) => {
            categoryChildrenFlatList[categorySlug] = categoryChildrenFlatList[categorySlug].flat(Infinity);
        });

        setCategoriesAndChildren(categoryChildrenFlatList);

        return nestedCategories;
    };

    const populateFilters = (filters: any) => {
        const [ area, categories, types ] = filters;

        if (area) {
            const areaOfCare = productsStore.availableAreasOfCare[area];

            if (areaOfCare) {
                changeAreaOfCare(areaOfCare);
            }
        }

        if (categories) {
            const allCategories = categories.split(',');
            allCategories.forEach((selectedCategory: string) => {
                const category = productsStore?.availableProductCategories.find((category) => category._data.slug === selectedCategory);
                if (category) {
                    changeSelectedProductCategory(category);
                }
            });
        }

        if (types) {
            const allTypes = types.split(',');
            allTypes.forEach((selectedType: string) => {
                const type = productsStore?.availableTypes.find((type) => type._data.slug === selectedType);
                if (type) {
                    changeSelectedType(type);
                }
            });
        }
    };

    createEffect(
        on(
            [() => searchParams.area, () => searchParams.categories, () => searchParams.types, () => productsStore.availableAreasOfCare],
            (filters) => populateFilters(filters)
        )
    );

    onCleanup(() => {
        clearFilters();
    });

    createEffect(() => setIsMobile(viewport.width <= theme.breakpoints.MOBILE));

    const breadcrumb = () => `${props?.labels?.pageHeading} ${productsStore.areaOfCare?.name ? `/ ${productsStore.areaOfCare?.name}` : ''}`;

    const createListOfTagsToMatch = (initialTags: string[]) => {
        // Start with the initial tags provided, and add any children of those tags to the list of tags to match
        let tagsAndChildren: any[] = [...initialTags];

        initialTags.forEach((tag?: string) => {
            if (!tag) return;

            if (!categoriesAndChildren() || !categoriesAndChildren()[tag]) {
                return;
            }
            
            const updatedList = [...tagsAndChildren, ...categoriesAndChildren()[tag]];
            tagsAndChildren = updatedList;
        });

        return tagsAndChildren;
    };

    const getAreaOfCareSlugToMatch = () => {
        // Get the search term for the area of care, either from the search params or the store, and ensure it's valid
        let area = searchParams.area || productsStore.areaOfCare?.slug;
        area = productsStore.availableAreasOfCare[ area || '' ] ? area : '';

        return area;
    };

    const getCategoriesToMatch = () => {
        // Get the search term for the product categories, either from the search params or the store, and ensure they're valid
        const searchParamCategories = searchParams?.categories?.split(',') || [];
        const storeCategories = productsStore?.selectedProductCategories?.map((category: NestedCategory) => category._data.slug);

        let selectedCategories = searchParamCategories.length
            ? searchParamCategories
            : storeCategories;

        selectedCategories = selectedCategories.filter((category: string) => {
            return productsStore.availableProductCategories.find((cat: NestedCategory) => {
                return cat._data.slug === category;
            });
        });

        return selectedCategories;
    };

    const getTypesToMatch = () => {
        // Get the search term for the types, either from the search params or the store, and ensure they're valid
        const searchParamTypes = searchParams?.types?.split(',') || [];
        const storeTypes = productsStore?.selectedTypes?.map((type: NestedCategory) => type._data.slug);

        let selectedTypes = searchParamTypes.length
            ? searchParamTypes
            : storeTypes;

        selectedTypes = selectedTypes.filter((type: string) => {
            return productsStore.availableTypes.find((t: NestedCategory) => {
                return t._data.slug === type;
            });
        });

        return selectedTypes;
    };

    const getSlugsToMatch = () => {
        const area = getAreaOfCareSlugToMatch();
        const selectedCategories = getCategoriesToMatch();
        const selectedTypes = getTypesToMatch();

        let slugsToMatch: string[] = [];

        if (selectedTypes.length > 0) {
            slugsToMatch = createListOfTagsToMatch(selectedTypes);
        } else if (selectedCategories.length > 0) {
            slugsToMatch = createListOfTagsToMatch(selectedCategories);
        } else {
            if (area) {
                slugsToMatch = createListOfTagsToMatch([area]);
            }
        }

        return slugsToMatch;
    };

    const results = () => {
        const slugsToMatch = getSlugsToMatch();  
        
        const results = productsData()
            ?.filter((prod: ProductListCardData) => {
                if (slugsToMatch.length === 0) return true;

                return slugsToMatch.find((tag?: string) => {
                    if (prod.tags?.includes(tag!)) {
                        return true;
                    }
                });
            });

        setProductsStore({ results: results });
        return results;
    };

    const handleOnClickArea = (area: string) => {
        // Just changes the url/search params (which in turn triggers the effect to run populateFilters)
        navigate(`${location.pathname}?area=${area}`);
    };

    const handleOnClickCategory = (category: NestedCategory) => {
        // Logic to update the selected product categories, then update the url/search params
        changeSelectedProductCategory(category);
        const allSelectedProductCategorySlugs = productsStore?.selectedProductCategories?.map((category: NestedCategory) => category._data.slug);
        const allCategorySlugsJoined = allSelectedProductCategorySlugs.join(',');

        if (allCategorySlugsJoined !== '') {
            navigate(`${location.pathname}?area=${searchParams.area}&categories=${allCategorySlugsJoined}`);
        } else {
            navigate(`${location.pathname}?area=${searchParams.area}`);
        }
    };

    const handleOnClickType = (type: NestedCategory) => {
        // Logic to update the selected types, then update the url/search params
        changeSelectedType(type);
        const allSelectedTypes = productsStore?.selectedTypes?.map((category: NestedCategory) => category._data.slug);
        const allSelectedTypesJoined = allSelectedTypes.join(',');

        if (allSelectedTypesJoined !== '') {
            navigate(`${location.pathname}?area=${searchParams.area}&categories=${searchParams.categories}&types=${allSelectedTypesJoined}`);
        } else {
            navigate(`${location.pathname}?area=${searchParams.area}&categories=${searchParams.categories}`);
        }
    };

    const handleOnClickClearFilters = (e: any) => {
        clearFilters(e);
        navigate(location.pathname);
    };

    return (
        <ErrorCatcher componentName="Products list">
            <Show when={loaded()} fallback={<LoadingPlaceHolder />}>
                <Section
                    templateShorthand={[12]}
                    widthType={'bgFull'}
                    heightType={'fill'}
                    backgroundType={'color'}
                    backgroundValue={'white'}
                    removeSpacingBlock={true}
                    customCss={'padding-top: 0; padding-bottom: 0;'}
                >
                    <Grid {...gridSettings.container}>
                        <StyledSidebarContainer>
                            <StyledSidebarContainerLine>
                                <StyledSidebar>
                                    <StyledSidebarInner>
                                        <StyledHeadingWrapper>
                                            <Heading tag="h1" variant={'giantRed'}>
                                                {localize('products', 'Products')}
                                            </Heading>
                                        </StyledHeadingWrapper>

                                        <Switch>
                                            <Match when={productsStore?.isMobile}>
                                                <MobileProductsSidebar
                                                    labels={props.labels}
                                                    store={{
                                                        store: productsStore,
                                                        changeAreaOfCare,
                                                        clearFilters,
                                                        changeSelectedProductCategory,
                                                        changeSelectedType,
                                                    }}
                                                    clickHandlers={{
                                                        handleOnClickArea,
                                                        handleOnClickCategory,
                                                        handleOnClickType,
                                                        handleOnClickClearFilters,
                                                    }}
                                                />
                                            </Match>
                                            <Match when={!productsStore?.isMobile}>
                                                <StyledFilterHeadingContainer>
                                                    <Heading tag="h2" variant="medium" noBlockSpacing={true}>
                                                        {localize('filters', 'Filters')}
                                                    </Heading>
                                                </StyledFilterHeadingContainer>

                                                <FilterList listHeading={localize('area-of-care', 'Area of care')}>
                                                    <For each={Object.keys(productsStore.availableAreasOfCare)}>
                                                        {(area) => (
                                                            <li>
                                                                <Radio
                                                                    value={productsStore.availableAreasOfCare[area]?._data?.slug}
                                                                    whenClicked={() => handleOnClickArea(area)}
                                                                    name="areaOfCare"
                                                                    readableName={productsStore.availableAreasOfCare[area]?._data?.name}
                                                                    isChecked={
                                                                        productsStore.areaOfCare?.slug === productsStore.availableAreasOfCare[area]?._data?.slug
                                                                    }
                                                                />
                                                            </li>
                                                        )}
                                                    </For>
                                                </FilterList>

                                                <Show when={productsStore.availableProductCategories.length > 0}>
                                                    <FilterList listHeading={localize('product-category', 'Product category')}>
                                                        <For each={productsStore.availableProductCategories}>
                                                            {(category) => (
                                                                <li>
                                                                    <Checkbox
                                                                        value={category?._data?.slug}
                                                                        whenClicked={() => handleOnClickCategory(category)}
                                                                        name={category?._data?.name}
                                                                        isChecked={
                                                                            !!productsStore?.selectedProductCategories?.find(
                                                                                (existing) => existing?._data?.slug === category?._data?.slug
                                                                            )
                                                                        }
                                                                    />
                                                                </li>
                                                            )}
                                                        </For>
                                                    </FilterList>
                                                </Show>

                                                <Show when={productsStore.availableTypes.length > 0}>
                                                    <FilterList listHeading={localize('type-of-product', 'Type of product')}>
                                                        <For each={productsStore.availableTypes}>
                                                            {(type) => (
                                                                <li>
                                                                    <Checkbox
                                                                        value={type?._data?.slug}
                                                                        whenClicked={() => handleOnClickType(type)}
                                                                        name={type?._data?.name}
                                                                        isChecked={
                                                                            !!productsStore.selectedTypes?.find(
                                                                                (existing) => existing?._data?.slug === type?._data?.slug
                                                                            )
                                                                        }
                                                                    />
                                                                </li>
                                                            )}
                                                        </For>
                                                    </FilterList>
                                                </Show>
                                            </Match>
                                        </Switch>

                                        <Show when={productsStore.areaOfCare}>
                                            <StyledClearAllContainer>
                                                <Button
                                                    label={localize('clear-all', 'Clear all')}
                                                    onClick={(e) => handleOnClickClearFilters(e)}
                                                    variant='tertiary'
                                                    noCaps={true}
                                                />
                                            </StyledClearAllContainer>
                                        </Show>
                                    </StyledSidebarInner>
                                </StyledSidebar>
                            </StyledSidebarContainerLine>
                        </StyledSidebarContainer>

                        <StyledProductsContainer>
                            <Show when={!productsStore.isMobile}>
                                <Heading tag="h2" variant="breadcrumb">
                                    {breadcrumb()}
                                </Heading>
                            </Show>
                            <StyledProductsList>
                                <Grid {...gridSettings.listing} customCss={''}>
                                    <For each={results()}>
                                        {(product: ProductListCardData) => {
                                            return createGroupedProductCard(product);
                                        }}
                                    </For>
                                </Grid>
                            </StyledProductsList>
                        </StyledProductsContainer>
                    </Grid>
                </Section>
            </Show>
        </ErrorCatcher>
    );
};

Products.parseProps = (atts: any) => {
    return {
        labels: {
            ...atts.labels,
            applyFiltersText: atts.labels?.showProductsButtonText,
        },
    };
};
